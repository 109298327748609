// 1) remove comment for default token 2) Make sure that when time is added to timer, the countdown adjusts 

import {useEffect, useState } from 'react';
import {Flex, Text, Button, Grid, GridItem} from '@chakra-ui/react';
import vault from './VaultABI.json';
import vaultX from './VaultXABI.json';
import { Widget } from "@kyberswap/widgets";
import { getBlock } from './currentBlock.js';
import { getVaultBalance } from './VaultBalance.js';
const { ethers } = require("ethers");
let currentBlock;
let VaultBalance;
let BlocksRemaining;
let LastBuyer;
let connectedWallet;
let TokenBalance;
let countToDate = null;
let _countToDate = null;


const vaultAddress = "0x109a884bAD41e69A3CA497E5407e9b8630dc80e7";
const HeistAddress = "0xd056Ff401D1e2442530F4cB72Ba9DbE95A6ed34D";

async function fetchCurrentBlock() {
  currentBlock = await getBlock();
  return currentBlock;
}

const MainMint = ({}) => {

  const [provider, setProvider] = useState(null);
  const [isHodlerClaimLoading, setIsHodlerClaimLoading] = useState(false); 
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState('');
    // Define a state variable to hold the connected wallet's address
  const [walletAddress, setWalletAddress] = useState('');
  const [vaultData, setVaultData] = useState({
    VaultBalanceUSD: 0, 
  });
  const [formattedDate, setFormattedDate] = useState('');


  const theme = {
    primary: '#0a2f5c',
    secondary: '#06152a',
    dialog:'#23262e',
    borderRadius:'20px',
    buttonRadius:'16px',
    stroke:'#2e2e2e',
    interactive:'#ffffff',
    accent:'#ffffff',
    success:'#ffffff',
    warning:'#ffffff',
    error:'#ffffff',
    text:'#ffffff',
    subtext:'#ffffff',
    fontFamily:'Kanit'
   };
   const ethersProvider = provider;
   const defaultTokenOut = {
     0x89: "0xd056Ff401D1e2442530F4cB72Ba9DbE95A6ed34D"
   };
   
   const chainId = 0x89;
   
   const tokenList = [
     {
       symbol: 'USDC',
       address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359', // Ethereum address (dummy address for ETH)
       name: 'USDC',
       decimals: 6,
       logoURI: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
     },
     {
       symbol: 'HEIST',
       address: '0xd056Ff401D1e2442530F4cB72Ba9DbE95A6ed34D', 
       name: 'HEIST',
       decimals: 18,
       logoURI: "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png",
     },
     // Add more tokens as needed
   ];

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// USEEFFECT SECTION
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  useEffect(() => {
    // Check if MetaMask is installed and Ethereum provider is available
    if (window.ethereum) {
      const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(ethProvider);
    } else {
      console.log('MetaMask not detected. Ethereum provider not available.');
    }
  }, []);

 useEffect(() => {
  // Check if MetaMask is installed and Ethereum provider is available
  if (window.ethereum) {
    const ethProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(ethProvider);
    // Request to connect MetaMask wallet
    window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(accounts => {
        setAccounts(accounts);
      })
      .catch(error => {
        console.error('MetaMask connection error:', error);
      });

    // Check if MetaMask is already connected with selectedAddress
    if (window.ethereum.selectedAddress) {
      setAccounts([window.ethereum.selectedAddress]);
    }

    // Listen for account changes
    window.ethereum.on('accountsChanged', newAccounts => {
      setAccounts(newAccounts);
    });
  } else {
    console.log('MetaMask not detected. Ethereum provider not available.');
  }
}, []);      

useEffect(() => {
  if (provider !== null) {
    fetchBundle();
    // Schedule fetchBundle to run every 2 seconds
    const bundleIntervalId = setInterval(fetchBundle, 5000);
    // Cleanup the interval when the component unmounts
    return () => clearInterval(bundleIntervalId);
  }
}, [provider, accounts]);

useEffect(() => {
  if (provider !== null) {
    fetchTimer();
    // Schedule fetchTimer to run every 1 second
    const timerIntervalId = setInterval(fetchTimer, 1000);
    // Cleanup the interval when the component unmounts
    return () => clearInterval(timerIntervalId);
  }
}, [provider]);

useEffect(() => {
  const interval = setInterval(() => {
    if (countToDate !='WAIT' && countToDate !='END') {
      setTimeRemaining(calculateTimeRemaining(new Date(countToDate)));
    }

    if (countToDate == 'WAIT' || countToDate == 'END') {
      setTimeRemaining(calculateTimeRemaining(countToDate));
    }
  }, 1000);

  return () => clearInterval(interval);
}, [countToDate]);      

useEffect(() => {
  const flashColors = () => {
    const elWallet = document.querySelector('.wallet-address');
    const elLastBuyer = document.querySelector('.last-buyer');
    
    let intervalId; 
    
      intervalId = setInterval(() => {
        if (LastBuyer.toLowerCase() === connectedWallet.toLowerCase()) {
          const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
          elWallet.style.color = color;
          elLastBuyer.style.color = color;
        } else {
          elWallet.style.color = '#ffffff';
          elLastBuyer.style.color = '#ffffff';
        }
      }, 500);
    

    return () => clearInterval(intervalId); // Clear interval in the cleanup function
  };
if (LastBuyer && connectedWallet) {
  flashColors();
}
}, [walletAddress, LastBuyer]);


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// FUNCTION SECTION
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


 const handleHodlerClaim = async () => {
  if (window.ethereum) {
    const signer = provider.getSigner();
    const HeistContract = new ethers.Contract(
      HeistAddress,
      vaultX.abi,
      signer
    );

    try {
      setIsHodlerClaimLoading(true); 
      // Call the claim function from HeistContract
      const claimTx = await HeistContract.claim();
      // Wait for the transaction to be mined
      await claimTx.wait();
    } catch (err) {
      console.error('Error claiming:', err);
    } finally {
      setIsHodlerClaimLoading(false); 
    }
  }
};

const handleClaim = async () => {
  if (window.ethereum) {
    const signer = provider.getSigner();
    const VaultContract = new ethers.Contract(
      vaultAddress,
      vault.abi,
      signer
    );

    try {
      setIsClaimLoading(true); 
      // Call the claim function from HeistContract
      const claimTx = await VaultContract.claim();
      // Wait for the transaction to be mined
      await claimTx.wait();
    } catch (err) {
      console.error('Error claiming:', err);
    } finally {
      setIsClaimLoading(false); 
    }
  }
};

  async function fetchVaultBalance() {
    VaultBalance = await getVaultBalance(vaultAddress);
  
    const newVaultData = {
      ...vaultData,
      LastBuyer: LastBuyer,
      VaultBalanceUSD: VaultBalance // Calculate real-time USD value of Vault 
    };
    setVaultData(newVaultData);

    return VaultBalance;
  }

  const calculateTimeRemaining = (endDate) => {
    const now = new Date().getTime();

    if (endDate == 'END') {
      return '24:00:00';
      console.log('1 - END');
    }

    if (endDate == 'WAIT') {
      return '00:00:00';
      console.log('1 - WAIT');
    }

    else {
    const distance = endDate - now;
  
    if (distance < 0) {
      return '00:00:00';
      console.log('00:00:00');
    }
  
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  };


  const fetchTimer = async () => {
    if (window.ethereum) {
      const VaultContract = new ethers.Contract(
        vaultAddress,
        vault.abi,
        provider
      );
      try {
      const TimerStarted = await VaultContract.timerStarted(); // Call the timerStarted read function
      const EndTimeBlock = (await VaultContract.endTimeBlock()).toNumber(); // Call the endTimeBlock read function
      await fetchCurrentBlock();

      let TimeRemainingInSecs;

      if (!TimerStarted) {
        BlocksRemaining = "Timer Hasn't Started Yet! Timer Will Start When The Vault Balance Reaches $1000 USDC";
        countToDate = 'END';
      } 
      else if (currentBlock > EndTimeBlock) {
        BlocksRemaining = "Timer Has Finished! Waiting for the winnings to be claimed...";
        countToDate = 'WAIT';
      }

      else {
        const _blocksRemaining = BlocksRemaining;
        BlocksRemaining = EndTimeBlock - currentBlock;
        TimeRemainingInSecs = Math.round((EndTimeBlock - currentBlock) * 2.15);
        const EndTimeDate = new Date(new Date().getTime() + TimeRemainingInSecs * 1000);
        const newFormattedDate = EndTimeDate.toLocaleString(); // Returns a string like "9/25/2021, 1:57:00 PM" based on the user's locale
        setFormattedDate(newFormattedDate); 

        if ((typeof BlocksRemaining === 'number' && typeof countToDate === 'string') || !_countToDate || _blocksRemaining < BlocksRemaining) {
          countToDate = EndTimeDate;
          _countToDate = countToDate;
          setTimeout(() => (_countToDate = null), 45000);
        }
      }

    } catch (err) {
      console.error('Error fetching timer:', err);
    }
    }
  }
  
    const fetchBundle = async () => {

      const VaultContract = new ethers.Contract(
            vaultAddress,
            vault.abi,
            provider
          );

          const HeistContract = new ethers.Contract(
            HeistAddress,
            vaultX.abi,
            provider
          );
      
          try {
            await fetchVaultBalance();
            LastBuyer = await VaultContract.lastBuyer();

            // Get the connected wallet's address
            //const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });       
            if (provider && accounts.length > 0) {
            const walletAddress = accounts[0];
            connectedWallet = walletAddress;
            setWalletAddress(accounts[0]);   
            TokenBalance = await (HeistContract.balanceOf(walletAddress))/1e18;
            
            const newVaultData = {
              ...vaultData,
              LastBuyer: LastBuyer,
              VaultBalanceUSD: VaultBalance           
            };
            setVaultData(newVaultData);
          } else {console.log('No accounts connected');}

          } catch (err) {
            console.error('Error fetching bundle:', err);
          }
      };
      
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// START HTML SECTION 
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      
    return (

      <Flex justify="center" align="bottom" height="90vh" paddingBottom="150px" style={{ overflowY: 'auto' }} flex="1">

      <Grid h='200px' width='85vw' templateRows='repeat(3, 1fr)' templateColumns='repeat(3, 1fr)' gap={5}>

        <GridItem colStart={2} colEnd={3}>
        <Text className="time-remaining" fontFamily="Kanit" fontWeight="bold" marginTop="0px" textShadow="0 4px #000000">Time Remaining:</Text>
        <Text className="countdown" textColor="#b50000" fontFamily="DS-DIGIB" marginBottom="10px">{timeRemaining}</Text>
        </GridItem>

        <GridItem colSpan={3} paddingBottom='30px'>
        <Text fontFamily="Kanit" fontWeight="bold" marginTop="0px" fontSize="20px" textShadow="0 0px #000000"> {isNaN(BlocksRemaining) ? '' : 'Blocks Left: '} {BlocksRemaining} </Text>
        
        {isNaN(BlocksRemaining) ? null : (
          <Text fontFamily="Kanit" fontWeight="bold" marginTop="-20px" fontSize="20px" textShadow="0 0px #000000"> Est. Date & Time: {formattedDate}</Text>
        )}
        </GridItem>

        <GridItem colSpan={2} position="relative">
          <Text 
          fontSize="20px" 
          textColor="#999999" 
          letterSpacing="-5.5%" 
          marginTop="100px" 
          lineHeight="10%" 
          fontFamily="Kanit" 
          fontWeight="700" 
          align="left"> 
          Vault Balance: 
          </Text>
          <Text 
          
          fontSize="25px" 
          letterSpacing="-5.5%" 
          lineHeight="100%" 
          fontFamily="Kanit" 
          textShadow="0 2px 2px #000000" 
          align="left">  
          ${vaultData.VaultBalanceUSD.toFixed(8)} 
          </Text>
          <Text 
          fontSize="20px" 
          textColor="#999999" 
          letterSpacing="-5.5%" 
          marginTop="50px" 
          lineHeight="10%" 
          fontFamily="Kanit" 
          fontWeight="700" 
          align="left">  
          Last Buyer: 
          </Text>
          <Text 
          className="last-buyer" 
          fontSize="25px" 
          letterSpacing="-5.5%" 
          lineHeight="100%" 
          fontFamily="Kanit" 
          textShadow="0 2px 2px #000000" 
          align="left"> 
          {vaultData.LastBuyer ? vaultData.LastBuyer.toLowerCase() : 'Loading...'} 
          </Text>
          <Text 
          fontSize="20px" 
          textColor="#999999" 
          letterSpacing="-5.5%" 
          marginTop="50px" 
          lineHeight="10%" 
          fontFamily="Kanit" 
          fontWeight="700" 
          align="left">
          Your Wallet: 
          </Text>
          <Text 
          className="wallet-address" 
          fontSize="25px" 
          letterSpacing="-5.5%" 
          lineHeight="100%" 
          fontFamily="Kanit" 
          textShadow="0 2px 2px #000000" 
          align="left"> 
          {walletAddress ? walletAddress.toLowerCase() : 'Loading...'}
          </Text>
          <Text 
          fontSize="20px" 
          textColor="#999999" 
          letterSpacing="-5.5%" 
          marginTop="50px" 
          lineHeight="10%" 
          fontFamily="Kanit" 
          fontWeight="700" 
          align="left">
          Your $HEIST Balance: 
          </Text>
          <Text 
          fontSize="25px" 
          letterSpacing="-5.5%" 
          lineHeight="100%" 
          fontFamily="Kanit" 
          textShadow="0 2px 2px #000000" 
          align="left"> 
          {TokenBalance !== undefined ? (TokenBalance === 0 ? '0' : TokenBalance.toFixed(3)) : 'Loading...'}          
          </Text>
          
          <Button 
            backgroundColor="#0855f4"
            border="0px"
            borderRadius="3px"
            color="white"
            cursor="pointer"
            fontFamily="Kanit"
            fontWeight="700"
            fontSize="15px"
            padding="15px"
            position="absolute"
            left="170"
            isLoading={isHodlerClaimLoading}
            loadingText="Claiming..."
            onClick={handleHodlerClaim}>
            CLAIM HODLER AIRDROP
          </Button>
          
          <Button 
            backgroundColor="#0855f4"
            border="0px"
            borderRadius="3px"
            color="white"
            cursor="pointer"
            fontFamily="Kanit"
            fontWeight="700"
            fontSize="15px"
            padding="15px"
            position="absolute"
            left="0"
            isLoading={isClaimLoading} // Set the isLoading prop based on the local state
            loadingText="Claiming..."
            onClick={handleClaim}>
            CLAIM VAULT
          </Button>
        </GridItem>

        <GridItem className="kyberswap-widget" position="relative" height="75vh">
        <Text align="right" paddingRight="135px" marginBottom="5px" fontFamily="Kanit" fontWeight="normal" fontSize="19px" >Set slippage to 4% or more ↓</Text>

        <Widget
          client="HEIST"
          theme={theme}
          tokenList={tokenList}
          enableRoute = {false}
          enableDexes="quickswap,retro,uniswap"
          provider={ethersProvider}
          //defaultTokenOut={defaultTokenOut[chainId]}
          title={<div> </div>}
        />
        </GridItem>
      
      <GridItem className="chart" colSpan={3}>
          <div style={{ position: 'relative', width: '100%', paddingBottom: '75%', height: '100%' }}>
            <iframe
              style={{ position: 'absolute', width: '100%', height: '80%', top: 50, left: 0, border: 0 }}
              src="https://dexscreener.com/ethereum/0x290A6a7460B308ee3F19023D2D00dE604bcf5B42?embed=1&theme=dark&trades=0&info=0"
            ></iframe>
          </div>
      </GridItem>
      </Grid>
    </Flex>
    );
};

export default MainMint;



