import Web3 from 'web3';

let decimalBlockNumber = null;

const fetchBlockFromWS = async () => {
  const providerUrl = process.env.REACT_APP_PROVIDER_URL;
  const web3 = new Web3(providerUrl);
  
  const latestBlock = await web3.eth.getBlockNumber();
  decimalBlockNumber = Number(latestBlock);
  setTimeout(() => decimalBlockNumber = null, 1000);
};

export const getBlock = async () => {
  if (decimalBlockNumber === null) {
     await fetchBlockFromWS();
  }
  return decimalBlockNumber;
};
