import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Link, Image } from '@chakra-ui/react';
import Logo from "./assets/social-media-icons/Logo.png";

const NavBar = ({ accounts, setAccounts }) => {
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        // Check if MetaMask is installed and Ethereum provider is available
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' })
                .then(accounts => {
                    setAccounts(accounts);
                    setIsConnected(true); // Set isConnected to true when accounts are available
                })
                .catch(error => {
                    console.error('MetaMask connection error:', error);
                    setIsConnected(false); // Set isConnected to false if connection fails
                });

            // Check if MetaMask is already connected with selectedAddress
            if (window.ethereum.selectedAddress) {
                setAccounts([window.ethereum.selectedAddress]);
                setIsConnected(true); // Set isConnected to true if already connected
            }

            // Listen for account changes
            window.ethereum.on('accountsChanged', newAccounts => {
                setAccounts(newAccounts);
                setIsConnected(newAccounts.length > 0); // Update isConnected based on new accounts
            });
        } else {
            setIsConnected(false); // Set isConnected to false if MetaMask is not detected
        }
    }, []);

    async function connectAccount() {
        if (window.ethereum) {
            const accounts = await window.ethereum.request({
                method: 'eth_requestAccounts',
            });
            setAccounts(accounts);
            setIsConnected(true);
        }
    }

    return (
        <Flex justify="space-between" align="center" padding="30px">
            <Flex justify="space-around" width="30%" padding="0 75px">
            <Image className="logo" src={Logo} alt="Icon" boxSize="55px" marginTop="-5px" marginRight="-100px" />

                <Link href="https://baseheist.gitbook.io/start/" target="_blank">
                    <Button
                        backgroundColor="#0855f4"
                        borderRadius="3px"
                        border="0px"
                        color="white"
                        cursor="pointer"
                        fontFamily="Kanit"
                        fontWeight="700"
                        fontSize="15px"
                        padding="12px"
                        margin="0 15px">
                        How To Play
                    </Button>
                </Link>
            </Flex>

            {/* right side - sections and connect*/}
            <Flex justify="space-around" align="center" width="20%">
                {/*connect*/}
                {isConnected ? (
                    <Box fontFamily="Poppins" fontSize="20px" margin="0 15px">Connected</Box>
                ) : (
                    <Button
                        backgroundColor="#0855f4"
                        borderRadius="3px"
                        border="0px"
                        color="white"
                        cursor="pointer"
                        fontFamily="Kanit"
                        fontWeight="700"
                        fontSize="15px"
                        padding="12px"
                        margin="0 15px"
                        onClick={connectAccount}>
                        Connect
                    </Button>
                )}
            </Flex>
        </Flex>
    );
};

export default NavBar;
