import vault from './VaultABI.json';
const { ethers } = require("ethers");

let VaultBalance = null;

const fetchVaultBalance = async (address) => {
  const providerUrl = process.env.REACT_APP_PROVIDER_URL;
  const provider = new ethers.providers.JsonRpcProvider(providerUrl);

  const contract = new ethers.Contract(address, vault.abi, provider);
  const _VaultBalance = await contract.vaultBalance();
  VaultBalance = (_VaultBalance.toNumber())/1e6;
  setTimeout(() => VaultBalance = null, 10000);
};

/////////////////////////////////////////////////////////////


export const getVaultBalance = async (vaultAddress) => {
  if (VaultBalance === null) {
     await fetchVaultBalance(vaultAddress);
  }
  return VaultBalance;
};
